import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonPopover,
    IonList,
    IonItem,
    IonLabel,
    IonToggle,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonSearchbar,
    IonSpinner,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import firebaseApp from '../firebase';
import FireMarker from '../components/FireMarker/FireMarker';
// import ExclusionMarker from '../components/ExclusionMarker/ExclusionMarker';
import './Page.css';
import { settingsSharp } from 'ionicons/icons';
import { PermitStore } from '../store/PermitStore';
import { TownStore } from '../store/TownStore';
import CurrentToggle from '../components/CurrentToggle';
const fbDb = firebaseApp.firestore();
// const mapKey = 'AIzaSyCnh3jo3JObQ7fEv8TmJH8TJFJo26tlsdY';
const google = window.google;

const Dashboard = () => {
    const townDoc = TownStore.useState((s) => s.townDoc);
    const permits = PermitStore.useState((s) => s.permits);
    const currentOnly = PermitStore.useState((s) => s.currentOnly);
    const currentPermits = PermitStore.useState((s) => s.currentPermits);
    const [mapPermits, setMapPermits] = useState(null);
    // const [town, setTown] = useState(null);
    const [mapDetails, setMapDetails] = useState({
        defaultCenter: { lat: townDoc?.latitude, lng: townDoc?.longitude },
        center: null,
        zoom: 12,
        defaultZoom: 12,
    });
    const [showMapOptions, setShowMapOptions] = useState({
        open: false,
        event: null,
        permits: true,
        exclusions: true,
    });
    const [blockedList, setBlockedList] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const municipality = 'West Lincoln';
    const permitsList = currentOnly ? currentPermits : !currentOnly ? permits : null;

    const apiIsLoaded = (map, blockedList) => {
        if (townDoc && blockedList) {
            blockedList.forEach((blk) => {
                if (blk.reason === 'health') {
                    const circle = new google.maps.Circle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.3,
                        map,
                        center: { lat: Number(blk.latitude), lng: Number(blk.longitude) },
                        radius: Number(townDoc.blockedRadius.size),
                    });
                    return circle;
                }
            });
        }
    };
    // useEffect(() => {
    //     console.log(permits);
    // }, [permits]);
    useEffect(() => {
        const getBlocks = () => {
            fbDb.collection('municipality')
                .doc(municipality)
                .collection('blockList')
                .get()
                .then((querySnapshot) => {
                    const bList = [];
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        let id = doc.id;
                        let data = doc.data();
                        // console.log(id, " => ", data);
                        bList.push({ id, ...data });
                    });
                    setBlockedList(bList);
                })
                .catch(function (error) {
                    console.log('Error getting document:', error);
                });
        };
        getBlocks();
    }, [municipality]);

    useEffect(() => {
        // console.log(town)
        if (townDoc && !mapDetails.center) {
            setMapDetails({
                ...mapDetails,
                center: { lat: Number(townDoc.latitude), lng: Number(townDoc.longitude) },
            });
        }
    }, [mapDetails, townDoc]);

    useEffect(() => {
        if (permitsList) {
            // setMapPermits(currentOnly ? currentPermits : permits);
            setMapPermits(permitsList);
        }
    }, [permitsList]);

    const _onChildClick = (key, childProps) => {
        setMapDetails({ center: { lat: childProps.lat, lng: childProps.lng }, zoom: 18 });
        // console.log(key, childProps)
    };
    const resetMap = () => {
        setMapDetails({
            center: { lat: Number(townDoc.latitude), lng: Number(townDoc.longitude) },
            zoom: 12,
        });
    };
    // const excludeColumns = ["checkedAgree"];
    const handleSearchChange = (value) => {
        // console.log(value);
        setSearchText(value);
        filterData(value);
    };
    const filterData = (value) => {
        if (permits) {
            const lowercasedValue = value.toLowerCase().trim();
            console.log(lowercasedValue);
            if (lowercasedValue === '') {
                setMapPermits(permits);
                // console.log('lowercasedValue ===', lowercasedValue)
            } else {
                // console.log('lowercasedValue ===', lowercasedValue)
                const filteredData = permits.filter((item) => {
                    return Object.keys(item).some((key) =>
                        item[key]
                            ? item[key].toString().toLowerCase().includes(lowercasedValue)
                            : false
                    );
                });
                setMapPermits(filteredData);
                // console.log(filteredData)
            }
        }
    };

    // useEffect(() => {
    //   console.log(permits);
    // }, [permits])
    return (
        <IonPage>
            <IonHeader mode='ios'>
                <IonToolbar color='primary'>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Dashboard</IonTitle>
                    <IonButtons slot='end'>
                        {mapDetails.zoom !== 12 ? (
                            <IonButton onClick={resetMap}>Reset</IonButton>
                        ) : null}
                        <IonButton
                            onClick={(e) =>
                                setShowMapOptions({
                                    ...showMapOptions,
                                    open: true,
                                    event: e.nativeEvent,
                                })
                            }
                        >
                            <IonIcon icon={settingsSharp} slot='icon-only'></IonIcon>
                        </IonButton>
                        <IonPopover
                            animated
                            mode='ios'
                            translucent={true}
                            showBackdrop={true}
                            backdropDismiss
                            event={showMapOptions.event}
                            isOpen={showMapOptions.open}
                            cssClass='my-custom-class'
                            onDidDismiss={(e) =>
                                setShowMapOptions({ ...showMapOptions, open: false, event: null })
                            }
                        >
                            <IonList>
                                <IonItem>
                                    <IonLabel className='ion-text-wrap'>Toggle Permits</IonLabel>
                                    <IonToggle
                                        checked={showMapOptions.permits}
                                        onIonChange={(e) => {
                                            setShowMapOptions({
                                                ...showMapOptions,
                                                permits: !showMapOptions.permits,
                                            });
                                        }}
                                    />
                                </IonItem>
                                <IonItem>
                                    <IonLabel className='ion-text-wrap'>Toggle Exclusions</IonLabel>
                                    <IonToggle
                                        checked={showMapOptions.exclusions}
                                        onIonChange={(e) =>
                                            setShowMapOptions({
                                                ...showMapOptions,
                                                exclusions: !showMapOptions.exclusions,
                                            })
                                        }
                                    />
                                </IonItem>
                            </IonList>
                        </IonPopover>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar className='ion-padding-top'>
                    <IonSearchbar
                        mode='ios'
                        animated
                        placeholder='Search'
                        debounce={1000}
                        value={searchText}
                        onIonChange={(e) => handleSearchChange(e.detail.value)}
                        showCancelButton='focus'
                    />
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>Dashboard</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonGrid>
                    <IonRow style={{ height: '50vh' }}>
                        <IonCol>
                            {townDoc && townDoc.latitude ? (
                                <GoogleMapReact
                                    // onBoundsChange={_onBoundsChange}
                                    onChildClick={_onChildClick}
                                    style={{ height: '100%', width: '100%' }}
                                    // bootstrapURLKeys={{ key: mapKey }}
                                    center={
                                        mapDetails.center
                                            ? mapDetails.center
                                            : {
                                                  center: {
                                                      lat: Number(townDoc.latitude),
                                                      lng: Number(townDoc.longitude),
                                                  },
                                              }
                                    }
                                    // defaultCenter={mapDetails.center}
                                    defaultZoom={mapDetails.defaultZoom}
                                    zoom={mapDetails.zoom}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map }) => apiIsLoaded(map, blockedList)}
                                    options={(maps) => ({
                                        mapTypeControl: true,
                                        mapTypeId: maps.MapTypeId.HYBRID,
                                        gestureHandling: 'cooperative',
                                    })}
                                >
                                    {mapPermits && showMapOptions.permits
                                        ? mapPermits.map((loc, i) =>
                                              loc.latitude ? (
                                                  <FireMarker
                                                      key={i}
                                                      lat={loc.latitude}
                                                      lng={loc.longitude}
                                                      name={loc.name}
                                                      color={loc.active ? 'danger' : 'warning'}
                                                      data={loc}
                                                      play={false}
                                                      pop={true}
                                                  />
                                              ) : null
                                          )
                                        : null}
                                    {/* {blockedList && showMapOptions.exclusions ? blockedList.map((excl, i) => (
                  <ExclusionMarker
                    key={i+'blk'}
                    lat={excl.latitude}
                    lng={excl.longitude}
                    name={excl.name}
                    type={excl.reason}
                    color={
                      excl.reason === 'health' ? 'secondary' : 
                      excl.reason === 'property' ? 'warning' :
                      excl.reason === 'compliance' ? 'danger' :
                      'danger'
                      }
                    data={excl}
                    play={false}
                    pop={true}
                    />
                )):
                  null
                } */}
                                </GoogleMapReact>
                            ) : (
                                <IonSpinner />
                            )}
                        </IonCol>
                    </IonRow>
                    <CurrentToggle />
                    <IonRow>
                        <IonCol sizeXs='12' sizeMd='3'>
                            <IonCard mode='ios'>
                                <IonCardHeader>
                                    <IonCardSubtitle>Total Permits</IonCardSubtitle>
                                    <IonCardTitle>{permitsList && permitsList.length}</IonCardTitle>
                                </IonCardHeader>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default Dashboard;
