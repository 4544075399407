import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCardHeader,
    IonList,
    IonRadio,
    IonRadioGroup,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardSubtitle,
    IonCardContent,
    IonListHeader,
    IonLabel,
    IonInput,
    IonCardTitle,
    IonButton,
    IonLoading,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Page.css';
import Geocode from 'react-geocode';
// import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import moment from 'moment';
// import GoogleMapReact from 'google-map-react';
// import FireMarker from '../components/FireMarker/FireMarker';
// import CheckoutForm from '../components/CheckoutForm';
import applicationData from '../components/data/application.json';
import { useHistory } from 'react-router';
import firebaseApp from '../firebase';

Geocode.setApiKey('AIzaSyA_lId_O_T9ATVe_2NkqUxoJ9fQCBUzZ2o');
// set response language. Defaults to english.
Geocode.setLanguage('en');
// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion('ca');
// Enable or disable logs. Its optional.
Geocode.enableDebug();
// const mapKey = 'AIzaSyCnh3jo3JObQ7fEv8TmJH8TJFJo26tlsdY';
const municipality = {
    name: 'Whiteston',
    state: 'ON',
    country: 'CA',
    address: '21 Church St, Dunchurch, ON P0A 1G0',
    lat: 45.644599678397455,
    lng: -79.85362377305319,
};
const fbDb = firebaseApp.firestore();
// const user = firebaseApp.auth().currentUser;
const New = () => {
    const history = useHistory();
    // const [ paymentReady, setPaymentReady ] = useState(false);
    const [randomId, setRandomId] = useState(null);
    // const [ geocode, setGeocode ] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [permit, setPermit] = useState({
        businessName: null,
        firstName: null,
        lastName: null,
        startDate: Date.now(),
        endDate: null,
        email: null,
        phone: null,
        type: null,
        address: null,
        from: Date.now(),
        to: null,
        paymentReceived: false,
        deptIssued: true,
        prepaid: false,
    });
    const permitTypes = applicationData.application.types;

    // function _onClick(obj){
    //   console.log(obj.lat, obj.lng);
    //   setGeocode({lat: obj.lat, lng: obj.lng})
    // }

    // useEffect(() => {
    //   const getGeoCode = () => {
    //     Geocode.fromAddress(permit.address).then(
    //       response => {
    //         const { lat, lng } = response.results[0].geometry.location;
    //         // console.log(lat, lng);
    //         setGeocode({lat: lat, lng: lng});
    //         setPermit({...permit, latitude: lat, longitude: lng})
    //       },
    //       error => {
    //         console.error(error);
    //       }
    //     );
    //   }
    //   getGeoCode();
    // }, [permit])
    // useEffect(() => {
    //     console.log(permit);
    // }, [permit]);

    // useEffect(() => {
    //   if (permit.email !== null) {
    //     setTimeout(() => {
    //       setPaymentReady(true)
    //     }, 5000)
    //   }
    // })
    // console.log(user)
    const createPermit = () => {
        setIsLoading(true);
        fbDb.collection('permits')
            .doc(permit.lastName + '-' + permit.type + '-' + permit.from)
            .set({
                firstName: permit.firstName ? permit.firstName : null,
                lastName: permit.lastName ? permit.lastName : null,
                type: permit.type ? permit.type : null,
                from: permit.from ? permit.from : null,
                to: permit.to ? permit.to : null,
                details: permit.details ? permit.details : null,
                startDate: permit.startDate ? moment(permit.startDate).format('YYYY-MM-DD') : null,
                endDate: permit.endDate ? moment(permit.endDate).format('YYYY') + '-10-31' : null,
                address: permit.address ? permit.address : null,
                businessName: permit.businessName ? permit.businessName : null,
                latitude: municipality ? municipality.lat : null,
                longitude: municipality ? municipality.lng : null,
                checkedAgree: true,
                checkedRead: true,
                active: true,
                user: permit.email,
                email: permit.email,
                phone: '+1' + permit.phone,
                paymentId: permit.prepaid ? randomId : null,
            })
            .then(() => {
                setTimeout(() => {
                    setIsLoading(false);
                    history.push(
                        `/success/${permit.lastName + '-' + permit.type + '-' + permit.startDate}`
                    );
                }, 1500);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    };
    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    useEffect(() => {
        const permitType = fbDb.collection('municipality/Whitestone/permitTypes');
        if (permit.type) {
            permitType
                .doc(permit.type)
                .get()
                .then((doc) => {
                    // console.log(doc.data());
                    setPermit({ ...permit, endDate: doc.data().validFor, to: doc.data().validFor });
                });
        }
    }, [permit.type]);
    useEffect(() => {
        setRandomId('mc_' + makeid(22));
    }, []);
    return (
        <IonPage>
            <IonLoading isOpen={isLoading} />
            <IonHeader>
                <IonToolbar color='primary' mode='ios'>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Add New Permit</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>Add New Permit</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonCard mode='ios'>
                                <IonCardHeader>
                                    <IonCardSubtitle>Create a new</IonCardSubtitle>
                                    <IonCardTitle>Burn Permit</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol sizeXs='12' sizeMd='6'>
                                                <IonList>
                                                    <IonListHeader>Applicant Details</IonListHeader>
                                                    <IonItem>
                                                        <IonLabel position='stacked'>
                                                            Email Address
                                                        </IonLabel>
                                                        <IonInput
                                                            placeholder='Enter resident email'
                                                            debounce='3000'
                                                            onIonChange={(e) => {
                                                                setPermit({
                                                                    ...permit,
                                                                    email: e.detail.value,
                                                                });
                                                            }}
                                                        />
                                                    </IonItem>
                                                    <IonItem>
                                                        <IonLabel position='stacked'>
                                                            First Name
                                                        </IonLabel>
                                                        <IonInput
                                                            placeholder='Enter first name'
                                                            onIonChange={(e) => {
                                                                setPermit({
                                                                    ...permit,
                                                                    firstName: e.detail.value,
                                                                });
                                                            }}
                                                        />
                                                    </IonItem>
                                                    <IonItem>
                                                        <IonLabel position='stacked'>
                                                            Last Name
                                                        </IonLabel>
                                                        <IonInput
                                                            placeholder='Enter last name'
                                                            onIonChange={(e) => {
                                                                setPermit({
                                                                    ...permit,
                                                                    lastName: e.detail.value,
                                                                });
                                                            }}
                                                        />
                                                    </IonItem>
                                                    <IonItem>
                                                        <IonLabel position='stacked'>
                                                            Phone Number
                                                        </IonLabel>
                                                        <IonInput
                                                            placeholder='Enter resident phone'
                                                            onIonChange={(e) => {
                                                                setPermit({
                                                                    ...permit,
                                                                    phone: e.detail.value,
                                                                });
                                                            }}
                                                        />
                                                    </IonItem>
                                                </IonList>
                                            </IonCol>
                                            <IonCol sizeXs='12' sizeMd='6'>
                                                <IonList>
                                                    <IonListHeader>Permit Location</IonListHeader>
                                                    <IonItem>
                                                        <IonLabel position='stacked'>
                                                            Address
                                                        </IonLabel>
                                                        <IonInput
                                                            placeholder='e.g. 21 Church St, Whitestone, ON'
                                                            onIonChange={(e) => {
                                                                setPermit({
                                                                    ...permit,
                                                                    address: e.detail.value,
                                                                });
                                                            }}
                                                        />
                                                    </IonItem>
                                                    {/* <IonItem>
                              <IonLabel position="stacked">Address</IonLabel>
                              <GooglePlacesAutocomplete
                                autocompletionRequest={{
                                  componentRestrictions: {
                                    country: ["ca"],
                                  },
                                  bounds: [
                                    { lat: 43.410409284072934, lng: -82.53843706400541 },
                                    { lat: 43.43786897484414, lng: -82.55272787401142 },
                                  ],
                                  strictBounds: true,
                                }}
                                onSelect={({ structured_formatting }) => {
                                  console.log({
                                    address: structured_formatting.main_text,
                                  })
                                  setPermit({ ...permit, address: structured_formatting.main_text + ', ' + structured_formatting.secondary_text })
                                  }
                                }
                                displayFromSuggestionSelected={({
                                  structured_formatting,
                                }) => structured_formatting.main_text}
                                apiKey={mapKey}
                                inputClassName='addressBox'
                                placeholder={permit.address || "Enter address"}
                                initialValue={permit.address || ""}
                                suggestionsClassNames={{
                                  container: "suggestionContainer",
                                  suggestion: "suggestionItem",
                                  suggestionActive: "suggestionActive",
                                }}
                              />
                              {/* <IonButton slot="end" disabled={permit.address === null ? true : false} onClick={() => getGeoCode()}>Get Coordinates</IonButton>
                            </IonItem> */}

                                                    <IonItem>
                                                        <IonLabel position='stacked'>
                                                            Business Name
                                                        </IonLabel>
                                                        <IonInput
                                                            placeholder='Enter business name if applicable'
                                                            onIonChange={(e) => {
                                                                setPermit({
                                                                    ...permit,
                                                                    businessName: e.detail.value,
                                                                });
                                                            }}
                                                        />
                                                    </IonItem>
                                                </IonList>
                                                <IonList>
                                                    <IonListHeader>Permit Type</IonListHeader>
                                                    <IonRadioGroup
                                                        value={permit.type}
                                                        onIonChange={(e) =>
                                                            setPermit({
                                                                ...permit,
                                                                type: e.detail.value,
                                                            })
                                                        }
                                                    >
                                                        {applicationData
                                                            ? permitTypes.map((type, i) => (
                                                                  <div key={i}>
                                                                      <IonItem
                                                                          aria-label={
                                                                              'Burn permit type'
                                                                          }
                                                                      >
                                                                          <IonLabel className='ion-text-wrap'>
                                                                              {type.name} -{' '}
                                                                              {type.desc}
                                                                          </IonLabel>
                                                                          <IonRadio
                                                                              slot='start'
                                                                              value={type.name}
                                                                          />
                                                                      </IonItem>
                                                                  </div>
                                                              ))
                                                            : null}
                                                    </IonRadioGroup>
                                                </IonList>
                                            </IonCol>
                                        </IonRow>

                                        <IonRow>
                                            <IonCol sizeXs='6'>
                                                <IonList>
                                                    <IonListHeader>Submit</IonListHeader>

                                                    {!permit.type ||
                                                    !permit.address ||
                                                    !permit.firstName ||
                                                    !permit.lastName ||
                                                    !permit.phone ? (
                                                        <div className='ion-padding'>
                                                            <h2 className='ion-padding-horizontal'>
                                                                Please complete required fields for
                                                                this permit
                                                            </h2>
                                                        </div>
                                                    ) : (
                                                        <div className='ion-padding'>
                                                            <div className='ion-padding ion-text-center'>
                                                                <IonGrid>
                                                                    <IonRow>
                                                                        <IonCol>
                                                                            <IonButton
                                                                                className='ion-margin-vertical'
                                                                                expand='block'
                                                                                onClick={() => {
                                                                                    createPermit();
                                                                                }}
                                                                            >
                                                                                Submit Application
                                                                            </IonButton>
                                                                        </IonCol>
                                                                    </IonRow>
                                                                </IonGrid>
                                                            </div>
                                                        </div>
                                                    )}
                                                </IonList>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default New;
