import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/functions';
import { toast } from './components/toast';

const firebaseConfig = {
  apiKey: "AIzaSyAWB0nOHxakPEwsSyNHS8ZzeP8-B_v3ogY",
  authDomain: "whitestone-fpo.firebaseapp.com",
  projectId: "whitestone-fpo",
  storageBucket: "whitestone-fpo.appspot.com",
  messagingSenderId: "179950844545",
  appId: "1:179950844545:web:b66ad384d1c4358860eb80",
  measurementId: "G-1F5WPGZ0K4"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.functions();
firebase.firestore();
export default firebaseApp;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export async function loginUser(email, password) {
    try {
      const res = await firebase.auth().signInWithEmailAndPassword(email, password)
      console.log(res)
      return true
    } catch(error) {
      toast(error.message, 4000)
      return false
    }
  }

export function logoutUser() {
  return firebase.auth().signOut()
}

