import {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCardHeader,
    IonList,
    IonRadio,
    IonRadioGroup,
    IonToggle,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonListHeader,
    IonLabel,
    IonCardTitle,
    IonButton,
    IonIcon,
    IonModal,
    IonText,
    IonAlert,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Page.css';
import Geocode from 'react-geocode';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';
import ExclusionMarker from '../components/ExclusionMarker/ExclusionMarker';
import MUIDataTable from 'mui-datatables';
import firebaseApp from '../firebase';
import { addCircleOutline, trashOutline } from 'ionicons/icons';
import { toast } from '../components/toast';

const google = window.google;

Geocode.setApiKey('AIzaSyBjiABpysbhr_3eI56zyfM6h1WAIIhjKgU');
// set response language. Defaults to english.
Geocode.setLanguage('en');
// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion('us');
// Enable or disable logs. Its optional.
Geocode.enableDebug();
const mapKey = 'AIzaSyCnh3jo3JObQ7fEv8TmJH8TJFJo26tlsdY';
// Return map bounds based on list of places

const fbDb = firebaseApp.firestore();
// const user = firebaseApp.auth().currentUser;
const Exclusions = () => {
    const municipality = 'Whitestone';
    const [town, setTown] = useState(null);
    const [blockedList, setBlockedList] = useState(null);
    const [blockedRadius, setBlockedRadius] = useState(null);
    const [blocked, setBlocked] = useState({
        address: null,
        reason: null,
        permiter: null,
        latitude: null,
        longitude: null,
        date: null,
    });
    const [currentBlock, setCurrentBlock] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [mapDetails, setMapDetails] = useState({
        defaultCenter: { lat: 45.64461467960341, lng: -79.85361304421745 },
        center: { lat: 45.64461467960341, lng: -79.85361304421745 },
        zoom: 12,
        defaultZoom: 12,
    });
    const options = {
        filterType: 'multiselect',
        elevation: 0,
        filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
        },
        selectableRows: 'none',
        customToolbarSelect: () => {},
        // filename: 'permits.csv'
    };
    const columns = [
        {
            name: 'address',
            label: 'Address',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'date',
            label: 'Date Added',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => moment(value).format('YYYY-MM-DD'),
            },
        },
        {
            name: 'reason',
            label: 'Reason',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <IonText className='ion-text-capitalize'>{value}</IonText>
                ),
            },
        },
        {
            name: 'id',
            label: 'Delete?',
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <IonButton fill='clear' color='primary' onClick={() => deleteBlockAlert(value)}>
                        <IonIcon icon={trashOutline} />
                    </IonButton>
                ),
            },
        },
    ];
    const deleteBlockAlert = (value) => {
        setCurrentBlock(value);
        setShowDeleteAlert(true);
    };
    const confirmDeleteBlock = () => {
        fbDb.collection('municipality')
            .doc(municipality)
            .collection('blockList')
            .doc(currentBlock)
            .delete()
            .then(() => {
                console.log('Document successfully deleted!');
                getBlocks();
                //   apiIsLoaded();
            })
            .catch(function (error) {
                console.error('Error removing document: ', error);
            });
    };
    const apiIsLoaded = (map, maps, center, blockedList) => {
        if (blockedList) {
            blockedList.forEach((blk) => {
                if (blk.reason === 'health') {
                    const circle = new google.maps.Circle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.3,
                        map,
                        center: { lat: Number(blk.latitude), lng: Number(blk.longitude) },
                        radius: 75,
                    });
                    return circle;
                }
            });
        }
    };

    const blockIt = () => {
        setShowModal(false);
        fbDb.collection('municipality')
            .doc(municipality)
            .collection('blockList')
            .doc(blocked.address)
            .set({
                ...blocked,
                date: Date.now(),
            })
            .then(() => {
                // console.log(res);
                toast('Exclusion List updated');
            })
            .catch((error) => {
                console.log(error);
                toast('There was an error updating your Exclusion list.');
            });
        getBlocks();
        setBlocked({
            address: null,
            reason: null,
            permiter: null,
            latitude: null,
            longitude: null,
            date: null,
        });
    };
    const getTown = () => {
        fbDb.collection('municipality')
            .doc(municipality)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data();
                    setTown(data);
                    setBlockedRadius(data.blockedRadius);
                } else {
                    // doc.data() will be undefined in this case
                    console.log('No such document!');
                }
            })
            .catch(function (error) {
                console.log('Error getting document:', error);
            });
    };
    const getBlocks = () => {
        fbDb.collection('municipality')
            .doc(municipality)
            .collection('blockList')
            .get()
            .then((querySnapshot) => {
                const bList = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    let id = doc.id;
                    let data = doc.data();
                    // console.log(id, " => ", data);
                    bList.push({ id, ...data });
                });
                setBlockedList(bList);
            })
            .catch(function (error) {
                console.log('Error getting document:', error);
            });
    };

    useEffect(() => {
        getTown();
        getBlocks();
    }, []);

    // useEffect(() => {
    //   // console.log(town)
    //   if (town) {
    //     setMapDetails({...mapDetails, center: {lat: Number(town.latitude), lng: Number(town.longitude)}});
    //   }
    // }, [mapDetails, town])
    // useEffect(() => {
    //   console.log(blockedList);
    // }, [blockedList])
    // useEffect(() => {
    //   console.log(blocked);
    // }, [blocked])
    useEffect(() => {
        const getGeoCode = () => {
            if (blocked.address) {
                Geocode.fromAddress(blocked.address).then(
                    (response) => {
                        const { lat, lng } = response.results[0].geometry.location;
                        // console.log(lat, lng);
                        setBlocked({ ...blocked, latitude: lat, longitude: lng });
                    },
                    (error) => {
                        console.error(error);
                    }
                );
            } else {
                console.log('awaiting address');
            }
        };
        getGeoCode();
    }, [blocked, blocked.address]);

    const _onChildClick = (key, childProps) => {
        setMapDetails({ center: { lat: childProps.lat, lng: childProps.lng }, zoom: 18 });
        console.log(key, childProps);
    };
    const resetMap = () => {
        setMapDetails({
            center: { lat: Number(town.latitude), lng: Number(town.longitude) },
            zoom: 12,
        });
    };
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color='primary' mode='ios'>
                    <IonButtons slot='start'>
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Exclusion List</IonTitle>
                    <IonButtons slot='end'>
                        {mapDetails.zoom !== 12 ? (
                            <IonButton onClick={resetMap}>Reset</IonButton>
                        ) : null}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonHeader collapse='condense'>
                    <IonToolbar>
                        <IonTitle size='large'>Exclusion List</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            {town && mapDetails ? (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '400px',
                                        maxHeight: '400px',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <GoogleMapReact
                                        onChildClick={_onChildClick}
                                        style={{ width: '100%' }}
                                        bootstrapURLKeys={{
                                            key: mapKey,
                                            libraries: [
                                                'places',
                                                'geometry',
                                                'drawing',
                                                'visualization',
                                            ],
                                        }}
                                        defaultCenter={{
                                            lat: 42.24809787439267,
                                            lng: -85.14051942292264,
                                        }}
                                        center={
                                            mapDetails
                                                ? mapDetails.center
                                                : {
                                                      center: {
                                                          lat: Number(town.latitude),
                                                          lng: Number(town.longitude),
                                                      },
                                                  }
                                        }
                                        defaultZoom={mapDetails.defaultZoom}
                                        zoom={mapDetails.zoom}
                                        yesIWantToUseGoogleMapApiInternals
                                        onGoogleApiLoaded={({ map, maps }) =>
                                            apiIsLoaded(
                                                map,
                                                maps,
                                                {
                                                    lat: Number(town.latitude),
                                                    lng: Number(town.longitude),
                                                },
                                                blockedList
                                            )
                                        }
                                        options={(maps) => ({
                                            mapTypeControl: true,
                                            mapTypeId: maps.MapTypeId.HYBRID,
                                            gestureHandling: 'cooperative',
                                        })}
                                    >
                                        {blockedList
                                            ? blockedList.map((loc, i) => (
                                                  <ExclusionMarker
                                                      key={i}
                                                      lat={loc.latitude}
                                                      lng={loc.longitude}
                                                      name={loc.name}
                                                      type={loc.reason}
                                                      color={
                                                          loc.reason === 'health'
                                                              ? 'secondary'
                                                              : loc.reason === 'property'
                                                              ? 'warning'
                                                              : loc.reason === 'compliance'
                                                              ? 'danger'
                                                              : 'danger'
                                                      }
                                                      data={loc}
                                                      play={false}
                                                      pop={true}
                                                  />
                                              ))
                                            : null}
                                    </GoogleMapReact>
                                </div>
                            ) : null}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard mode='ios'>
                                <IonToolbar>
                                    <IonButtons slot='end'>
                                        <IonButton onClick={() => setShowModal(true)}>
                                            <IonIcon icon={addCircleOutline} />
                                            &nbsp;New
                                        </IonButton>
                                    </IonButtons>
                                </IonToolbar>
                                <IonCardHeader>
                                    <IonCardTitle>Exclusions List</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol>
                                                {blockedList !== null ? (
                                                    <MUIDataTable
                                                        data={blockedList}
                                                        columns={columns}
                                                        options={options}
                                                    />
                                                ) : (
                                                    <p>No blocked addresses, yet.</p>
                                                )}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <IonModal
                    mode='ios'
                    isOpen={showModal}
                    onDidDismiss={() => {
                        setShowModal(false);
                    }}
                >
                    <IonToolbar>
                        <IonTitle>Add Address</IonTitle>
                        <IonButtons slot='end'>
                            <IonButton onClick={() => setShowModal(false)}>
                                <IonIcon icon='close' />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <IonContent>
                        <IonList>
                            <IonListHeader>Search for an address</IonListHeader>
                            <IonItem>
                                <IonLabel position='stacked'>Address</IonLabel>
                                <GooglePlacesAutocomplete
                                    autocompletionRequest={{
                                        componentRestrictions: {
                                            country: ['us'],
                                        },
                                        bounds: [
                                            { lat: 42.16125513141754, lng: -85.06087639653197 },
                                            { lat: 42.24568971378936, lng: -85.18035270595466 },
                                        ],
                                        strictBounds: true,
                                    }}
                                    onSelect={(res) => {
                                        console.log({
                                            res,
                                        });
                                        setBlocked({
                                            ...blocked,
                                            address:
                                                res.structured_formatting.main_text +
                                                ', ' +
                                                res.structured_formatting.secondary_text,
                                        });
                                    }}
                                    displayFromSuggestionSelected={({ structured_formatting }) =>
                                        structured_formatting.main_text
                                    }
                                    // apiKey={mapKey}
                                    inputClassName='addressBox'
                                    placeholder={'Enter address'}
                                    initialValue={''}
                                    suggestionsClassNames={{
                                        container: 'suggestionContainer',
                                        suggestion: 'suggestionItem',
                                        suggestionActive: 'suggestionActive',
                                    }}
                                />
                                {/* <IonButton slot="end" disabled={permit.address === null ? true : false} onClick={() => getGeoCode()}>Get Coordinates</IonButton> */}
                            </IonItem>
                            <IonRadioGroup
                                onIonChange={(e) =>
                                    setBlocked({ ...blocked, reason: e.target.value })
                                }
                            >
                                <IonListHeader>
                                    <IonLabel>Reason</IonLabel>
                                </IonListHeader>
                                <IonItem>
                                    <IonLabel>Property Deficient</IonLabel>
                                    <IonRadio value='property' />
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Non-compliance</IonLabel>
                                    <IonRadio value='compliance' />
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Health Concern</IonLabel>
                                    <IonRadio value='health' />
                                </IonItem>
                            </IonRadioGroup>
                            {blocked.reason && blocked.reason === 'health' ? (
                                <IonItem>
                                    <IonLabel>Add Perimeter</IonLabel>
                                    {blocked.permiter ? (
                                        <IonLabel className='ion-text-wrap'>
                                            Please note: A {blockedRadius.size}{' '}
                                            {blockedRadius.unit === 'meters'
                                                ? 'meter'
                                                : blockedRadius.unit === 'yard'
                                                ? 'yard'
                                                : blockedRadius.unit === 'feet'
                                                ? 'foot'
                                                : null}{' '}
                                            exclusion radius will be placed around this address.
                                        </IonLabel>
                                    ) : null}
                                    <IonToggle
                                        checked={blocked.permiter}
                                        onIonChange={(e) =>
                                            setBlocked({ ...blocked, permiter: e.target.checked })
                                        }
                                    />
                                </IonItem>
                            ) : null}
                        </IonList>
                        <div className='ion-padding'>
                            <IonButton
                                expand='block'
                                disabled={blocked.reason && blocked.latitude ? false : true}
                                onClick={blockIt}
                            >
                                Submit!
                            </IonButton>
                        </div>
                    </IonContent>
                </IonModal>
                <IonAlert
                    isOpen={showDeleteAlert}
                    onDidDismiss={() => setShowDeleteAlert(false)}
                    cssClass='my-custom-class'
                    header={'Please Confirm'}
                    message={`Click confirm to delete this property from the Exclusion List`}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                setShowDeleteAlert(false);
                                setCurrentBlock(null);
                            },
                        },
                        {
                            text: 'Okay',
                            handler: () => {
                                confirmDeleteBlock();
                            },
                        },
                    ]}
                />
            </IonContent>
        </IonPage>
    );
};

export default Exclusions;
