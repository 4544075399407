import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
  
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});
  
const Terms = () => {
    
    return (
        
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={{marginVertical:10}}>
          <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 18, textAlign: 'center', fontWeight: 'bold', marginVertical: 5}}>PLEASE CAREFULLY REVIEW THE TERMS & CONDITIONS.</Text>  
            <View style={{height:20}} />
            <View>
              <Text style={{fontFamily: 'Helvetica-Bold', fontSize:16, fontWeight: 'bold'}}>Per By-Law 37-2020:</Text>
              <View style={{height:20}} />
              <Text style={{fontSize: 13, marginBottom: 10}}>a. This permit is for a Camp Fire for cooking and/or socialization, day or night</Text>
              <Text style={{fontSize: 13, marginBottom: 10}}>b. The Camp Fire must be situated on bare rock or non-combustible material</Text>
              <Text style={{fontSize: 13, marginBottom: 10}}>c. The Camp Fire must be surrounded by at least one (1) metre of non-combustible material</Text>
              <Text style={{fontSize: 13, marginBottom: 10}}>d. The Camp Fire must be situated at least three (3) metres from any flammable material</Text>
              <Text style={{fontSize: 13, marginBottom: 10}}>e. The Camp Fire must be less than one (1) metres in diameter and less than one (1) metre in high</Text>
              <Text style={{fontSize: 13, marginBottom: 10}}>f. If the Fire Hazard Rating is HIGH or EXTREME, the Camp Fire cannot be set before 6:00pm and must be extinguished completely before 6:00am</Text>
              <Text style={{fontSize: 13, marginBottom: 10}}>g. The Camp Fire must be attended by a responsible person until the fire is extinguished completely</Text>
              <View style={{height:20}} />
              <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 16, marginBottom: 10, fontWeight: 'bold'}}>You must have a means to extinguish the Fire completely:</Text>
              <Text style={{fontSize: 13, marginBottom: 10}}>- Shovel</Text>
              <Text style={{fontSize: 13, marginBottom: 10}}>- Garden hose</Text>
              <Text style={{fontSize: 13, marginBottom: 10}}>- Pail of water</Text>
            </View>
              <View style={{height:20}} />
            <Text style={{fontSize: 16, marginBottom: 10}}>This permit shall be available at the site of the Fire at all times while the Fire is set.</Text> 
            <View style={{height:20}} />
            <Text style={{fontFamily: 'Helvetica-Bold', fontSize: 16, marginBottom: 10, fontWeight: 'bold'}}>IN CASE OF EMERGENCY CALL 911</Text> 
          </View>    
        </View>
      </Page>
    );
};

export default Terms;
  